import '../styles/index.scss'
import '~/choices.js/public/assets/styles/choices.min.css'
import '~/flatpickr/dist/flatpickr.min.css'

import Choices from 'choices.js'
import flatpickr from 'flatpickr'
import { French } from './flatpickr.locale.js'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

class App {
  constructor () {
    this._input = document.getElementById('input')
    this._inputContainer = document.getElementById('input-container')
    this._resultsPreviewContainer = document.getElementById('results-preview-container')
    this._resultsCodeContainer = document.getElementById('results-code-container')
    this._resultsTable = document.getElementById('results-table')
    this._notification = document.getElementById('notification')
    this._fadeOutNotifTimeout = null

    this._flatpickr = null
    this._chocies = null

    this._initDayjs()
    this._initFlatpickr()
    this._fixFlatpickrChoices()
    this._initInputEvents()

    this._refreshDates(dayjs())
  }

  _initDayjs () {
    dayjs.locale(window.I18n.locale)
  }

  _initFlatpickr () {
    this._flatpickr = flatpickr(this._input, {
      locale: window.I18n.locale === 'fr' ? French : null,
      enableTime: true,
      time_24hr: true,
      defaultDate: Date.now(),
      inline: true
    })

    const flatpickrElement = document.querySelector('.flatpickr-calendar')
    this._choices = new Choices(flatpickrElement.querySelector('select'), {
      shouldSort: false,
      searchEnabled: false
    })
  }

  _fixFlatpickrChoices () {
    let preventClosing = false
    this._flatpickr.config.onClose.push(() => {
      if (preventClosing) this._flatpickr.open()
    })
    this._choices.passedElement.element.addEventListener('change', () => {
      preventClosing = true
      requestAnimationFrame(() => {
        preventClosing = false
      })
    })

    this._flatpickr.config.onMonthChange.push(() => {
      this._choices.setChoiceByValue(this._flatpickr.currentMonth.toString())
    })
  }

  _initInputEvents () {
    this._input.addEventListener('change', () => {
      this._refreshDates(dayjs(this._input.value))
    })
  }

  _printClipboardSuccess () {
    this._showNotification(window.I18n.texts.notification.success, 'success')
  }

  _printClipboardFail () {
    this._showNotification(window.I18n.texts.notification.fail, 'error')
  }

  _refreshDates (date) {
    this._resultsPreviewContainer.innerHTML = ''
    this._resultsCodeContainer.innerHTML = ''
    this._resultsTable.innerHTML = `
      <thead>
        <tr>
          <td>${window.I18n.texts.code}</td>
          <td>${window.I18n.texts.preview}</td>
        </tr>
      </thead>
      <tbody>
      </tbody>
    `
    const formats = {
      f: 'LLL',
      F: 'LLLL',
      d: 'L',
      D: 'LL',
      t: 'LT',
      T: 'LTS',
      R: null
    }

    Object.keys(formats).forEach(discordFormat => {
      const standardFormat = formats[discordFormat]
      const code = `<t:${date.unix()}:${discordFormat}>`
      let preview
      if (discordFormat == 'R') {
        preview = date.fromNow()
      } else {
        preview = date.format(standardFormat)
      }

      /* Message */
      const element = document.createElement('span')
      const br = document.createElement('br')
      element.className = 'result-preview'
      element.innerText = preview
      this._resultsPreviewContainer.appendChild(element)
      this._resultsPreviewContainer.appendChild(br)

      /* Message edit */
      const codeElement = document.createElement('span')
      const brCode = document.createElement('br')
      codeElement.innerText = code
      this._resultsCodeContainer.appendChild(codeElement)
      this._resultsCodeContainer.appendChild(brCode)

      /* Table */
      const tbody = this._resultsTable.querySelector('tbody')
      const tr = document.createElement('tr')
      const tdCode = document.createElement('td')
      const tdPreview = document.createElement('td')
      tr.className = 'result-line'
      tdCode.className = 'result-code'
      tdCode.innerText = code
      tdPreview.innerText = preview
      tr.appendChild(tdCode)
      tr.appendChild(tdPreview)
      tbody.appendChild(tr)
    })

    this._initResultsEvents()
  }

  _initResultsEvents () {
    const resultLines = this._resultsTable.querySelectorAll('.result-line')
    for (let i = 0; i < resultLines.length; i++) {
      const resultLine = resultLines[i]
      resultLine.addEventListener('click', () => {
        navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
          if (result.state == 'granted' || result.state == 'prompt') {
            navigator.clipboard.writeText(resultLine.querySelector('.result-code').innerText).then(() => {
              this._printClipboardSuccess()
            }, () => {
              this._printClipboardFail()
            })
          } else {
            this._printClipboardFail()
          }
        })
      })
    }
  }

  _showNotification (message, state) {
    const states = ['error', 'success']
    if (!~states.indexOf(state)) throw new Error('Unknown state: ' + state)
    this._notification.innerText = message
    this._notification.classList.add('visible')

    states.forEach(state => this._notification.classList.remove(state))
    this._notification.classList.add(state)

    if (this._fadeOutNotifTimeout) {
      clearTimeout(this._fadeOutNotifTimeout)
    }
    this._fadeOutNotifTimeout = setTimeout(() => {
      this._fadeOutNotifTimeout = null
      this._notification.classList.remove('visible')
    }, 5000)
  }
}

window.DiscordPicker = new App()
